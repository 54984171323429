import "../../Styles/HomePage.css";
import Header from "../Sections/Header";
import "../../Styles/style.css";
import Footer from "../Sections/Footer";
import IlNousFontConfiance from "../Sections/IlNousFontConfiance";
import { useRef, useEffect, useState } from "react";
import emailjs from "@emailjs/browser";
import Carousel from "react-bootstrap/Carousel";
import AOS from "aos";

function HomePage() {
  useEffect(() => {
    // titre de la page
    document.title = "ATOM TECH - Solutions Numériques Innovantes";

    // initialiser AOS
    AOS.init({
      // once: true,
      // disable: "phone",
      duration: 700,
      easing: "ease-out-cubic",
    });
  }, []);

  //preparation et envoi du mails d'inscription a la newsletter

  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [newsletterError, setNewsletterError] = useState("");
  const [newsletterSucces, setNewsletterSucces] = useState("");

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();
    if (!validateEmail(email)) {
      setEmailError("Veuillez entrer une adresse email valide.");
      return;
    } else {
      console.log(email.value);
      emailjs
        .sendForm("atom tech", "template_jsq25b5", form.current, {
          publicKey: "rEsMf1KtmhgfPjMuM",
          email: email,
        })
        .then(
          () => {
            setNewsletterSucces("Inscription réussi");
          },
          (error) => {
            setNewsletterError("Désolé, un problème est survenu");
          }
        );
    }
    setEmailError("");
  };

  // carousel
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };
  // carousel

  return (
    <>
      {/* header */}
      <Header />
      {/* header */}

      {/* Carousel */}
      <Carousel
        activeIndex={index}
        onSelect={handleSelect}
        controls={false}
        touch={true}
      >
        <Carousel.Item>
          <section className="atom-main-slider banner-slider" id="home">
            <div className="owl-one owl-carousel owl-theme">
              <div className="item">
                <div
                  className="slider-info banner-view banner-top1"
                  id="caroussel1"
                >
                  <div className="container">
                    <div className="banner-info">
                      <h3 className="">
                        Transformez vos idées en réalité avec{" "}
                        <span>ATOM TECH</span>
                      </h3>
                      <div className="banner-info-top">
                        <p>
                          Développons ensemble des solutions numériques
                          innovantes pour votre entreprise. Contactez-nous dès
                          aujourd'hui pour démarrer votre projet.
                        </p>
                        <a
                          href="/contact"
                          className="btn btn-style btn-outline-light mt-sm-5 mt-4 btn-contactUs"
                          id="btnContactCarousel"
                        >
                          CONTACTEZ-NOUS
                          <span className="fas fa-angle-double-right ms-2"></span>{" "}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </Carousel.Item>
        <Carousel.Item>
          <section
            className="atom-main-slider banner-slider"
            id="home caroussel1"
          >
            <div className="owl-one owl-carousel owl-theme">
              <div className="item">
                <div
                  className="slider-info banner-view banner-top1"
                  id="caroussel2"
                >
                  <div className="container">
                    <div className="banner-info">
                      <h3 className="">
                        Accélérez votre succès avec <span>ATOM TECH</span>
                      </h3>
                      <div className="banner-info-top">
                        <p>
                          Découvrez des solutions sur mesure pour booster votre
                          croissance. Contactez-nous dès aujourd'hui pour en
                          savoir plus.
                        </p>
                        <a
                          href="/contact"
                          className="btn btn-style btn-outline-light mt-sm-5 mt-4 btn-contactUs "
                          id="btnContactCarousel"
                        >
                          CONTACTEZ-NOUS
                          <span className="fas fa-angle-double-right ms-2"></span>{" "}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </Carousel.Item>
        <Carousel.Item>
          <section className="atom-main-slider banner-slider" id="home">
            <div className="owl-one owl-carousel owl-theme">
              <div className="item">
                <div
                  className="slider-info banner-view banner-top1"
                  id="caroussel3"
                >
                  <div className="container">
                    <div className="banner-info">
                      <h3 className="">
                        Elevez votre entreprise avec <span>ATOM TECH</span>
                      </h3>
                      <div className="banner-info-top">
                        <p>
                          Explorer des technologies innovantes pour un avenir
                          radieux. Contactez-nous dès aujourd'hui pour commencer
                          votre voyage.
                        </p>
                        <a
                          href="/contact"
                          className="btn btn-style btn-outline-light mt-sm-5 mt-4 btn-contactUs"
                          id="btnContactCarousel"
                        >
                          CONTACTEZ-NOUS
                          <span className="fas fa-angle-double-right ms-2"></span>{" "}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </Carousel.Item>
      </Carousel>

      {/* Carousel */}

      {/* block grid header */}
      <div class="atom-3-grids" id="grids-3">
        <div class="container-fluid mx-lg-0">
          <div class="row pt-sm-0 pt-5">
            <div class="col-lg-4 col-sm-6 mt-sm-0 m px-md-0 w3-gridhny-1 position-relative">
              <div class="grids3-info">
                <a href="#" class="d-block zoom">
                  <img
                    src="img/g1.jpg"
                    alt="créativité"
                    class="img-fluid news-image"
                  />
                </a>
                <div class="w3-grids3-info">
                  <h6>
                    <a
                      href="/about-us"
                      class="category d-block linkFirstGrid"
                    >
                      Créativité
                    </a>
                  </h6>
                  <h4>
                    <a href="/about-us">
                      Innovons ensemble pour un monde plus audacieux.
                    </a>
                  </h4>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-sm-6 mt-sm-0 px-md-0 w3-gridhny-1 position-relative">
              <div class="grids3-info">
                <a href="/about-us" class="d-block zoom">
                  <img
                    src="img/g2.jpg"
                    alt="professionalisme"
                    class="img-fluid news-image"
                  />
                </a>
                <div class="w3-grids3-info second">
                  <h6>
                    <a href="/about-us" class="category d-block">
                      Professionalisme
                    </a>
                  </h6>
                  <h4>
                    <a href="/about-us">
                      Expertise et rigueur pour des résultats impeccables.
                    </a>
                  </h4>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-sm-6 mt-lg-0 px-md-0 w3-gridhny-1 position-relative">
              <div class="grids3-info">
                <a href="#blog" class="d-block zoom">
                  <img
                    src="img/g3.jpg"
                    alt="service clients"
                    class="img-fluid news-image"
                  />
                </a>
                <div class="w3-grids3-info">
                  <h6>
                    <a href="#category" class="category d-block">
                      Services clients
                    </a>
                  </h6>
                  <h4>
                    <a href="#">Votre satisfaction, notre priorité absolue.</a>
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* block grid header */}

      {/* <about-section */}
      <section class="atom-index2" id="about" b>
        <div class="midd-w3 py-5">
          <div class="container py-md-5 py-3">
            {/* <!--/row-1--> */}
            <div class="row">
              <div
                data-aos="fade-right"
                class="col-lg-6 mt-lg-0 mt-md-5 mt-4 align-self ps-lg-5"
                id="slide-in-left"
              >
                <div class="title-content text-left">
                  <h6 class="mb-2 text-left titleAboutHome1">
                    <span class="titleAboutHome">Notre mission</span>
                  </h6>
                  <h3 class="title-atom titleAboutHome">
                    Booster les potentiels des entreprises et individus grâce à
                    <span class="span-bold"> la technologie.</span>
                  </h3>
                </div>
                <p class="mt-md-4 mt-3 textAboutus ">
                  Chez ATOM TECH, nous nous engageons à fournir des solutions
                  numériques innovantes et de haute qualité pour aider nos
                  clients à réussir dans un monde compétitif. Notre vision est
                  de devenir un leader reconnu en repoussant les limites de la
                  technologie pour un impact positif sur nos clients et la
                  société
                </p>
                <div class="atom-two-buttons">
                  <a
                    href="/services"
                    class="btn-style mt-lg-5 mt-4 btnReadMoreAboutHome"
                  >
                    En savoir plus{" "}
                    <span class="fas fa-angle-double-right ms-2"></span>
                  </a>
                  <a
                    href="/contact"
                    class="btn-style mt-lg-5 mt-4 ms-2 btnContactUsAboutHome"
                  >
                    Contactez-nous
                    <span class="fas fa-angle-double-right ms-2"></span>
                  </a>
                </div>
              </div>
              <div data-aos="zoom-in" class="col-lg-6">
                <div class=" imageAboutHome">
                  <img
                    src="img/g4.jpg"
                    alt="contactez-nous"
                    class="radius-image img-fluid"
                  />
                </div>
              </div>
            </div>
            {/* <!--//row-1--> */}
          </div>
        </div>
      </section>
      {/* <about-section */}

      {/* services */}
      <section class="atom-grids-3 home-page-gds py-5">
        <div class="container py-md-5 py-3">
          <div class="header-sec">
            <h3 class="title-atom two mb-4" id="titleService">
              Découvrez nos solutions numériques innovantes <br /> pour vous
              propulser vers {""}
              <span class="span-bold">le succès.</span>
            </h3>
          </div>
          <div class="row bottom_grids text-left">
            <div class="col-lg-3 col-md-6 mt-5">
              <div class="grid-block text-center">
                <a href="/services" class="d-block p-lg-4 p-3 linkService">
                  <span class="fas bi bi-code-slash" aria-hidden="true">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="26"
                      height="26"
                      fill="currentColor"
                      class="bi bi-code-slash"
                      viewBox="0 0 16 16"
                      style={{ fontWeight: "bold" }}
                    >
                      <path d="M10.478 1.647a.5.5 0 1 0-.956-.294l-4 13a.5.5 0 0 0 .956.294zM4.854 4.146a.5.5 0 0 1 0 .708L1.707 8l3.147 3.146a.5.5 0 0 1-.708.708l-3.5-3.5a.5.5 0 0 1 0-.708l3.5-3.5a.5.5 0 0 1 .708 0m6.292 0a.5.5 0 0 0 0 .708L14.293 8l-3.147 3.146a.5.5 0 0 0 .708.708l3.5-3.5a.5.5 0 0 0 0-.708l-3.5-3.5a.5.5 0 0 0-.708 0" />
                    </svg>
                  </span>
                  <h4 class="my-3">Développement Web</h4>
                  <p class="">
                    Créez un site web performant et esthétique pour votre
                    entreprise.
                  </p>
                </a>
              </div>
            </div>
            <div class="col-lg-3 col-md-6 mt-5">
              <div class="grid-block text-center">
                <a href="/services" class="d-block p-lg-4 p-3 linkService">
                  <span class="fas fa-bullhorn" aria-hidden="true"></span>
                  <h4 class="my-3">Marketing Digital</h4>

                  <p class="">
                    Boostez votre visibilité en ligne avec nos stratégies de
                    marketing digital.
                  </p>
                </a>
              </div>
            </div>
            <div class="col-lg-3 col-md-6 mt-5">
              <div class="grid-block text-center">
                <a href="/services" class="d-block p-lg-4 p-3 linkService">
                  <span class="fas fa-chart-pie" aria-hidden="true"></span>
                  <h4 class="my-3">Design Graphique</h4>
                  <p class="">
                    Des designs uniques et attrayants pour votre marque.
                  </p>
                </a>
              </div>
            </div>
            <div class="col-lg-3 col-md-6 mt-5">
              <div class="grid-block text-center">
                <a href="/services" class="d-block p-lg-4 p-3 linkService">
                  <span class="fas fa-headset" aria-hidden="true"></span>
                  <h4 class="my-3">Coaching en informatique</h4>
                  <p class="">
                    Améliorez vos compétences numériques avec nos sessions de
                    coaching.
                  </p>
                </a>
              </div>
            </div>
          </div>
          <div class="row bottom_grids text-left">
            <div class="col-lg-3 col-md-6 mt-5">
              <div class="grid-block text-center">
                <a href="/services" class="d-block p-lg-4 p-3 linkService">
                  <span class="fas bi bi-code-slash" aria-hidden="true">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="26"
                      height="26"
                      fill="currentColor"
                      class="bi bi-code-slash"
                      viewBox="0 0 16 16"
                      style={{ fontWeight: "bold" }}
                    >
                      <path d="M10.478 1.647a.5.5 0 1 0-.956-.294l-4 13a.5.5 0 0 0 .956.294zM4.854 4.146a.5.5 0 0 1 0 .708L1.707 8l3.147 3.146a.5.5 0 0 1-.708.708l-3.5-3.5a.5.5 0 0 1 0-.708l3.5-3.5a.5.5 0 0 1 .708 0m6.292 0a.5.5 0 0 0 0 .708L14.293 8l-3.147 3.146a.5.5 0 0 0 .708.708l3.5-3.5a.5.5 0 0 0 0-.708l-3.5-3.5a.5.5 0 0 0-.708 0" />
                    </svg>
                  </span>
                  <h4 class="my-3">Hébergement Web</h4>
                  <p class="">
                    Un hébergement web sécurisé et fiable pour votre site.
                  </p>
                </a>
              </div>
            </div>
            <div class="col-lg-3 col-md-6 mt-5">
              <div class="grid-block text-center">
                <a href="/services" class="d-block p-lg-4 p-3 linkService">
                  <span class="fas fa-bullhorn" aria-hidden="true"></span>
                  <h4 class="my-3">Optimisation SEO</h4>

                  <p class="">
                    Optimisez votre site pour les moteurs de recherche avec nos
                    outils SEO.
                  </p>
                </a>
              </div>
            </div>
            <div class="col-lg-3 col-md-6 mt-5">
              <div class="grid-block text-center">
                <a href="/services" class="d-block p-lg-4 p-3 linkService">
                  <span class="fas fa-chart-pie" aria-hidden="true"></span>
                  <h4 class="my-3">Mail Pro</h4>
                  <p class="">
                    Des solutions de messagerie professionnelle pour une
                    communication efficace.
                  </p>
                </a>
              </div>
            </div>
            <div class="col-lg-3 col-md-6 mt-5">
              <div class="grid-block text-center">
                <a href="/services" class="d-block p-lg-4 p-3 linkService">
                  <span class="fas fa-headset" aria-hidden="true"></span>
                  <h4 class="my-3">Assistance en informatique</h4>
                  <p class="">
                    Un support informatique rapide et efficace pour tous vos
                    besoins.
                  </p>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* services */}

      {/* ce que nous faisons */}
      <section class="atom-services2" id="services">
        <div id="cwp23-block" class="py-5">
          <div class="container py-lg-5">
            <div class="row cwp23-content mt-lg-5 mt-4">
              <div
                data-aos="fade-right"
                class="col-lg-6 cwp23-text mt-lg-0 mt-5 ps-lg-5"
              >
                <div class="cwp23-text-cols">
                  <div class="column">
                    <img
                      src="img/g2.jpg"
                      alt="sur nous"
                      class="radius-image img-fluid"
                    />
                  </div>
                  <div class="column">
                    <img
                      src="img/g6.jpg"
                      alt="acceuil "
                      class="radius-image img-fluid"
                    />
                  </div>
                  <div class="column">
                    <img
                      src="img/g4.jpg"
                      alt="ce que nous faisons"
                      class="radius-image img-fluid"
                    />
                  </div>
                  <div class="column">
                    <img
                      src="img/g5.jpg"
                      alt="que faisons-nous?"
                      class="radius-image img-fluid"
                    />
                  </div>
                </div>
              </div>
              <div data-aos="zoom-in" class="col-lg-6 cwp23-img whatWeDoHome">
                <h6 class="title-subhny mb-2 titleAboutHome1">
                  <span>Que faisons-nous ?</span>
                </h6>
                <h3 class="title-atom titleAboutHome ">
                  Nos services sur mesure sont pensés pour satisfaire
                  <br />
                  <span class="span-bold">vos exigences.</span>
                </h3>
                <p class="mt-2">
                  Notre approche personnalisée garantit une solution adaptée à
                  vos besoins spécifiques, vous offrant ainsi une expérience sur
                  mesure et des résultats optimaux."
                </p>

                <div class="atom-button mt-lg-5 mt-1">
                  <a
                    href="/contact"
                    class="btn-style mt-lg-5 mt-1 btnReadMoreAboutHome"
                  >
                    Contactez-Nous{" "}
                    <span class="fas fa-angle-double-right ms-2"></span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* ce que nous faisons */}

      {/* newsletter */}
      <section class="atom-project-main pb-5" id="Newsletter">
        <div class="container pb-md-5 py-2">
          <div class="atom-project p-md-5 p-4 px-4">
            <div class="row py-lg-4 px-lg-2 align-items-center">
              <div class="col-lg-7 atom-project-right">
                <div class="bottom-info">
                  <div class="header-section pr-lg-5">
                    <h3 class="title-atom two">
                      Recevez nos mises à jour en vous abonnant à notre
                      <br />
                      <span class="span-bold">newsletter hebdomadaire !</span>
                    </h3>
                  </div>
                </div>
              </div>
              <div class="col-lg-5 atom-project-left mt-lg-0 mt-4">
                <form
                  ref={form}
                  onSubmit={sendEmail}
                  action=""
                  class="subscribe firstBlockNewsletter"
                  method="post"
                  required
                >
                  <input
                    type="email"
                    name="email"
                    placeholder="Votre adresse Email"
                    required=""
                    id="emailNewsletterHome"
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <button>
                    <span class="fas fa-paper-plane"></span>
                  </button>
                </form>
                {emailError && (
                  <p style={{ color: "red", textAlign: "left" }}>
                    {emailError}
                  </p>
                )}
                {setNewsletterError && (
                  <p style={{ color: "red", textAlign: "left" }}>
                    {newsletterError}
                  </p>
                )}
                {setNewsletterError && (
                  <p style={{ color: "white", textAlign: "left" }}>
                    {newsletterSucces}
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* newsletter */}

      {/* ils nous font confiance */}
      <IlNousFontConfiance />
      {/* ils nous font confiance */}

      {/* statistiques */}
      <div class="blockStatistique">
        <div class="opacityBlockStatistique">
          <div class="container">
            <div class="row">
              <div class="col-md-3 projetStatistique">
                <p class="chiffreStatistique">50+</p>
                <p class="textStatistique">- Projets réalisés</p>
              </div>
              <div class="col-md-3 projetStatistique">
                <p class="chiffreStatistique">70+</p>
                <p class="textStatistique">- Clients satisfaits</p>
              </div>
              <div class="col-md-3 projetStatistique">
                <p class="chiffreStatistique">10+</p>
                <p class="textStatistique">- Partenaires</p>
              </div>
              <div class="col-md-3 projetStatistique">
                <p class="chiffreStatistique">5+</p>
                <p class="textStatistique">- Entreprises accompagnées</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* statistiques */}

      {/* Footer */}
      <Footer />
      {/* Footer */}
    </>
  );
}

export default HomePage;
