import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.css";
import HomePage from "./Pages/HomePage";
import ContactUspage from "./Pages/ContactUsPage";
import NotFoundPage from "./Pages/NotFoundPage";
import AboutPage from "./Pages/AboutPage";
import ServicesPages from "./Pages/ServicesPages";
import "bootstrap/dist/css/bootstrap.min.css";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/about-us" element={<AboutPage />} />
          <Route path="/contact" element={<ContactUspage />} />
          <Route path="/services" element={<ServicesPages />} />
          <Route path="/*" element={<ContactUspage />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
