import "../../Styles/AboutPage.css";
import Footer from "../Sections/Footer";
import Header from "../Sections/Header";
import "../../Styles/style.css";
import { useRef, useEffect, useState } from "react";
import emailjs from "@emailjs/browser";
import AOS from "aos";

function AboutPage() {
  useEffect(() => {
    // titre de la page
    document.title =
      "ATOM TECH - Qui Sommes-Nous ? Créativité, Professionalisme et Services Clients";

    // initialiser AOS
    AOS.init({
      // once: true,
      // disable: "phone",
      duration: 700,
      easing: "ease-out-cubic",
    });
  }, []);

  //preparation et envoi du mails d'inscription a la newsletter

  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [newsletterError, setNewsletterError] = useState("");
  const [newsletterSucces, setNewsletterSucces] = useState("");

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    if (!validateEmail(email)) {
      setEmailError("Veuillez entrer une adresse email valide.");
      return;
    } else {
      emailjs
        .sendForm("atom tech", "template_jsq25b5", form.current, {
          publicKey: "rEsMf1KtmhgfPjMuM",
          email: email,
        })
        .then(
          () => {
            setNewsletterSucces("Inscription réussi");
          },
          (error) => {
            setNewsletterError("Désolé, un problème est survenu");
          }
        );
    }
    setEmailError("");
  };

  return (
    <>
      <Header />

      {/* Header 2  */}
      <section class="w3l-about-breadcrumb">
        <div
          class="breadcrumb-bg breadcrumb-bg-about py-5"
          id="headerAboutPage"
        >
          <div class="container py-lg-5 py-md-4">
            <div class="w3breadcrumb-gids text-center pt-5">
              <div class="w3breadcrumb-right" id="w3breadcrumb-right">
                <ul class="breadcrumbs-custom-path">
                  <li>
                    <a href="/">Acceuil</a>
                  </li>
                  <li class="active">
                    <span class="fas fa-angle-double-right mx-2"></span> Qui
                    sommes-nous ?{" "}
                  </li>
                </ul>
                <h1 className="titleAboutPage">A Propos</h1>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Header 2 */}

      {/* feature with photo1  */}
      <section class="atom-feature-with-photo-1">
        <div class="feature-with-photo-hny py-5">
          <div class="container py-lg-5">
            <div class="feature-with-photo-content">
              <div class="ab-in-flow row mb-lg-5 mb-3">
                <div data-aos="fade-right" class="col-lg-6 ab-left pe-lg-5">
                  <img
                    src="img/g2.jpg"
                    class="img-fluid radius-image"
                    alt="header"
                    id="imageFirstBlockAbout"
                  />
                </div>
                <div data-aos="zoom-in" class="col-lg-6 ab-right pl-lg-5">
                  <h3 class="title-atom mb-4">
                    Nous sommes une entreprise jeune et créative et nous vous
                    proposons de nouvelles {""}
                    <span class="span-bold">solutions numérique.</span>
                  </h3>
                  <p class="my-1 text-left textSectionAbout">
                    Chez ATOM TECH, nous nous engageons à fournir des solutions
                    numériques innovantes et de haute qualité pour aider nos
                    clients à réussir dans un monde compétitif. Notre vision est
                    de devenir un leader reconnu en repoussant les limites de la
                    technologie pour un impact positif sur nos clients et la
                    société
                  </p>

                  <div
                    class="atom-button mt-lg-1 mt-1"
                    style={{ display: "flex", alignItems: "left" }}
                  >
                    <a
                      href="/contact"
                      class="btn-style mt-lg-5 mt-1 btnReadMoreAboutHome"
                    >
                      Contactez-Nous{" "}
                      <span class="fas fa-angle-double-right ms-2"></span>
                    </a>
                  </div>
                </div>
              </div>
              <div class="three-grids d-grid grid-columns-3 mt-lg-5 pt-lg-4">
                <div class="grid">
                  <h3 class="title-atom mb-4" id="titleQueFaisonsNous">
                    Que
                    <span class="span-bold"> faisons-nous ?</span>
                  </h3>
                </div>
                <div class="grid">
                  <div class="three-grids-icon">1</div>
                  <h4 id="textAboutus">
                    <a href="#">Notre Vision</a>
                  </h4>
                  <p id="textAboutus">
                    Devenir leader des solutions numériques impactantes.
                  </p>
                </div>
                <div class="grid">
                  <div class="three-grids-icon">2</div>
                  <h4 id="textAboutus">
                    <a href="#">Notre Mission</a>
                  </h4>
                  <p id="textAboutus">
                    Fournir des solutions numérique exceptionnelles pour
                    propulser vos objectifs commerciaux.
                  </p>
                </div>
                <div class="grid">
                  <div class="three-grids-icon">3</div>
                  <h4 id="textAboutus">
                    <a href="#">Nos Valeurs</a>
                  </h4>
                  <p id="textAboutus">
                    Créativité, professionnalisme et service client au coeur de
                    nos actions. {"                        "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* feature with photo1 */}

      {/* statistiques */}
      <div class="blockStatistique" id="blockStatistiqueAbout">
        <div class="opacityBlockStatistique">
          <div class="container">
            <div class="row">
              <div class="col-md-3 projetStatistique">
                <p class="chiffreStatistique">50+</p>
                <p class="textStatistique">- Projets réalisés</p>
              </div>
              <div class="col-md-3 projetStatistique">
                <p class="chiffreStatistique">70+</p>
                <p class="textStatistique">- Clients satisfaits</p>
              </div>
              <div class="col-md-3 projetStatistique">
                <p class="chiffreStatistique">10+</p>
                <p class="textStatistique">- Partenaires</p>
              </div>
              <div class="col-md-3 projetStatistique">
                <p class="chiffreStatistique">5+</p>
                <p class="textStatistique">- Entreprises accompagnées</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* statistiques */}

      {/* ce que nous faisons */}
      <section class="atom-services2" id="services">
        <div id="cwp23-block" class="py-5">
          <div class="container py-lg-5">
            <div class="row cwp23-content mt-lg-5 mt-4">
              <div
                data-aos="fade-right"
                class="col-lg-6 cwp23-img whatWeDoHome"
              >
                <h6 class="title-subhny mb-2 titleAboutHome1">
                  <span>A propos de nos services ?</span>
                </h6>
                <h3 class="title-atom titleAboutHome ">
                  Nos services sur mesure sont pensés pour satisfaire
                  <br />
                  <span class="span-bold">vos exigences.</span>
                </h3>
                <p class="mt-2">
                  Notre approche personnalisée garantit une solution adaptée à
                  vos besoins spécifiques, vous offrant ainsi une expérience sur
                  mesure et des résultats optimaux."
                </p>

                <div class="atom-button mt-lg-5 mt-1">
                  <a
                    href="/contact"
                    class="btn-style mt-lg-5 mt-1 btnReadMoreAboutHome"
                  >
                    Contactez-Nous{" "}
                    <span class="fas fa-angle-double-right ms-2"></span>
                  </a>
                </div>
              </div>
              <div
                data-aos="zoom-in"
                class="col-lg-6 cwp23-text mt-lg-0 mt-5 ps-lg-5"
              >
                <div class="cwp23-text-cols">
                  <div class="column">
                    <img
                      src="img/g2.jpg"
                      alt="contact"
                      class="radius-image img-fluid"
                    />
                  </div>
                  <div class="column">
                    <img
                      src="img/g6.jpg"
                      alt="a propos 2"
                      class="radius-image img-fluid"
                    />
                  </div>
                  <div class="column">
                    <img
                      src="img/g4.jpg"
                      alt="a propos"
                      class="radius-image img-fluid"
                    />
                  </div>
                  <div class="column">
                    <img
                      src="img/g5.jpg"
                      alt="sur nous 2"
                      class="radius-image img-fluid"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* ce que nous faisons */}

      {/* newsletter */}
      <section class="atom-project-main pb-5" id="Newsletter">
        <div class="container pb-md-5 py-2">
          <div class="atom-project p-md-5 p-4 px-4">
            <div class="row py-lg-4 px-lg-2 align-items-center">
              <div class="col-lg-7 atom-project-right">
                <div class="bottom-info">
                  <div class="header-section pr-lg-5">
                    <h3 class="title-atom two">
                      Recevez nos mises à jour en vous abonnant à notre
                      <br />
                      <span class="span-bold">newsletter hebdomadaire !</span>
                    </h3>
                  </div>
                </div>
              </div>
              <div class="col-lg-5 atom-project-left mt-lg-0 mt-4">
                <form
                  ref={form}
                  onSubmit={sendEmail}
                  action=""
                  class="subscribe firstBlockNewsletter"
                  method="post"
                  required
                >
                  <input
                    type="email"
                    name="email"
                    placeholder="Votre adresse Email"
                    required=""
                    id="emailNewsletterAbout"
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <button>
                    <span class="fas fa-paper-plane"></span>
                  </button>
                </form>
                {emailError && (
                  <p style={{ color: "red", textAlign: "left" }}>
                    {emailError}
                  </p>
                )}
                {setNewsletterError && (
                  <p style={{ color: "red", textAlign: "left" }}>
                    {newsletterError}
                  </p>
                )}
                {setNewsletterError && (
                  <p style={{ color: "white", textAlign: "left" }}>
                    {newsletterSucces}
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* newsletter */}

      <Footer />
    </>
  );
}

export default AboutPage;
