import { useEffect, useRef, useState } from "react";
import "../../Styles/Header.css";
import "../../Styles/style.css";
import { useLocation } from "react-router-dom";

function Header() {
  // State for handling scroll and navbar collapse
  const [isScrolled, setIsScrolled] = useState(false);
  const [isNavbarCollapsed, setIsNavbarCollapsed] = useState(true);
  const navbarRef = useRef(null);

  // Handle scroll to update isScrolled state
  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset;

      if (scrollTop > 0) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // Handle navbar toggle button click
  const handleNavbarToggle = () => {
    setIsNavbarCollapsed(!isNavbarCollapsed);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (navbarRef.current && !navbarRef.current.contains(event.target)) {
        setIsNavbarCollapsed(true);
      }
    };

    if (!isNavbarCollapsed) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isNavbarCollapsed]);

  // Update active link based on current path
  const location = useLocation();

  useEffect(() => {
    const currentPath = location.pathname;
    let linkHome = document.getElementById("linkHome");
    let linkAbout = document.getElementById("linkAbout");
    let linkServices = document.getElementById("linkServices");
    let linkContact = document.getElementById("linkContact");

    if (currentPath === "/") {
      linkHome.classList.add("active");
    } else {
      linkHome.classList.remove("active");
    }

    if (currentPath === "/about-us") {
      linkAbout.classList.add("active");
    } else {
      linkAbout.classList.remove("active");
    }

    if (currentPath === "/services") {
      linkServices.classList.add("active");
    } else {
      linkServices.classList.remove("active");
    }

    if (currentPath === "/contact") {
      linkContact.classList.add("active");
    } else {
      linkContact.classList.remove("active");
    }
  }, [location]);

  return (
    <>
      <header
        id="site-header"
        className={isScrolled ? "nav-fixed fixed-top" : "fixed-top"}
      >
        <div className="container">
          <nav
            ref={navbarRef}
            className="navbar navbar-expand-lg navbar-light stroke py-lg-0"
          >
            <h1>
              <a
                className={
                  isScrolled
                    ? "navbar-brand pe-xl-5 pe-lg-4 logoDesable"
                    : "navbar-brand pe-xl-5 pe-lg-4 logoEnable"
                }
                id="logoWhiteHeader"
                href="/"
              >
                <img src="logo/logoAtomTechWhite.png" alt="logoATOMTECH" />
              </a>
              <a
                className={
                  isScrolled
                    ? "navbar-brand pe-xl-5 pe-lg-4 logoEnable"
                    : "navbar-brand pe-xl-5 pe-lg-4 logoDesable"
                }
                id="logoBlackHeader"
                href="/"
              >
                <img src="logo/logo.png" alt="logoATOMTECH" />
              </a>
            </h1>
            <button
              className={`navbar-toggler ${
                !isNavbarCollapsed ? "" : "collapsed"
              }`}
              type="button"
              onClick={handleNavbarToggle}
              aria-controls="navbarScroll"
              aria-expanded={!isNavbarCollapsed}
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon fa icon-expand fa-bars"></span>
              <span className="navbar-toggler-icon fa icon-close fa-times"></span>
            </button>
            <div
              className={`collapse navbar-collapse ${
                isNavbarCollapsed ? "" : "show"
              }`}
              id="navbarScroll"
            >
              <ul className="navbar-nav me-lg-auto my-2 my-lg-0 navbar-nav-scroll">
                <li className="nav-item">
                  <a
                    className="nav-link active"
                    id="linkHome"
                    aria-current="page"
                    href="/"
                  >
                    Acceuil
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    id="linkAbout"
                    href="/about-us"
                  >
                    Qui sommes-nous ?
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" id="linkServices" href="/services">
                    Nos Services
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    id="linkContact"
                    href="/contact"
                  >
                    Contact
                  </a>
                </li>
              </ul>
              <ul className="navbar-nav search-right mt-lg-0 mt-2">
                <li className="nav-item mx-lg-4">
                  <a
                    href="#Newsletter"
                    className="phone-btn btn-white d-none d-lg-block btn-style ms-2 d-flex"
                    style={{ textDecoration: "none" }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      fill="currentColor"
                      className="bi bi-envelope-arrow-up-fill me-2"
                      viewBox="0 0 16 16"
                      style={{ marginBottom: "3px" }}
                    >
                      <path d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414zM0 4.697v7.104l5.803-3.558zm.192 8.159 6.57-4.027L8 9.586l1.239-.757.367.225A4.49 4.49 0 0 0 8 12.5c0 .526.09 1.03.256 1.5H2a2 2 0 0 1-1.808-1.144M16 4.697v4.974A4.5 4.5 0 0 0 12.5 8a4.5 4.5 0 0 0-1.965.45l-.338-.207z" />
                      <path d="M12.5 16a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7m.354-5.354 1.25 1.25a.5.5 0 0 1-.708.708L13 12.207V14a.5.5 0 0 1-1 0v-1.717l-.28.305a.5.5 0 0 1-.737-.676l1.149-1.25a.5.5 0 0 1 .722-.016" />
                    </svg>
                    Newsletter
                  </a>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </header>
    </>
  );
}

export default Header;
