import { useEffect, useState } from "react";
import "../../Styles/IlNousFontConfiance.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

function IlNousFontConfiance() {
  // Liste des logos d'entreprise
  const logos = [
    "/IlsNousFontConfiance/sublime.png",
    "/IlsNousFontConfiance/logo TechPro.png",
    "/IlsNousFontConfiance/titomarket.png",
    "/IlsNousFontConfiance/logoSith.png",
    "/IlsNousFontConfiance/KEC-LAB 2.png",
  ];

  let settings = {
    dots: true,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
    ],
  };

  return (
    <div className="container slider-container">
      <h3 className="titleIlsNousFontConfiance">Ils nous font confiance</h3>
      <div className="slider-logo">
        <Slider {...settings}>
          <div>
            <h3>
              <img
                src="/IlsNousFontConfiance/sublime.png"
                class="logoIlNousFontoConfiance"
                id="logoSublimeCamer"
                alt="logoSublimeCamer"
              />
            </h3>
          </div>
          <div>
            <h3>
              <img
                src="/IlsNousFontConfiance/logo TechPro.png"
                class="logoIlNousFontoConfiance"
                id="logoTechPro"
                alt="logoTechPro"
              />
            </h3>
          </div>
          <div>
            <h3>
              <img
                src="/IlsNousFontConfiance/titomarket.png"
                class="logoIlNousFontoConfiance"
                id="logoTitoMarket"
                alt="logoTitoMarket"
              />
            </h3>
          </div>
          <div>
            <h3>
              <img
                src="/IlsNousFontConfiance/logoSith.png"
                class="logoIlNousFontoConfiance"
                id="logoSith"
                alt="logoSith"
              />
            </h3>
          </div>
          <div>
            <h3>
              <img
                src="/IlsNousFontConfiance/KEC-LAB 2.png"
                class="logoIlNousFontoConfiance"
                id="logoKecLab"
                alt="logoKecLab"
              />
            </h3>
          </div>
        </Slider>
      </div>
    </div>
  );
}

export default IlNousFontConfiance;
