import "../../Styles/style.css";
import "../../Styles/Footer.css";
import { Link } from "react-router-dom";
import emailjs from "@emailjs/browser";
import { useRef, useState } from "react";

function Footer() {
  // When the user scrolls down 20px from the top of the document, show the button
  window.onscroll = function () {
    scrollFunction();
  };

  function scrollFunction() {
    if (
      document.body.scrollTop > 20 ||
      document.documentElement.scrollTop > 20
    ) {
      document.getElementById("movetop").style.display = "block";
    } else {
      document.getElementById("movetop").style.display = "none";
    }
  }

  // When the user clicks on the button, scroll to the top of the document
  let btnSroll = document.getElementById("movetop");
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Fait défiler en douceur jusqu'au début de la page
    });
  };

  //preparation et envoi du mails d'inscription a la newsletter

  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();
    if (!validateEmail(email)) {
      setEmailError("Veuillez entrer une adresse email valide.");
      return;
    } else {
      console.log(email.value);
      emailjs
        .sendForm("atom tech", "template_jsq25b5", form.current, {
          publicKey: "rEsMf1KtmhgfPjMuM",
          email: "test",
        })
        .then(
          () => {
            alert("Inscription réussi");
          },
          (error) => {
            alert("Désolé, un problème est survenu");
          }
        );
    }
    setEmailError("");
  };

  return (
    <>
      <footer class="atom-footer-29-main">
        <div class="footer-29 py-5">
          <div class="container py-lg-4">
            <div class="row footer-top-29 mt-md-5 mt-4">
              <div class="col-lg-4 col-md-6 footer-list-29 footer-1 pe-lg-5">
                <h6 class="footer-title-29">A propos</h6>
                <div class="footer-listw3-grids">
                  <p class="pe-lg-4 t-left">
                    Chez <b>ATOM TECH</b>, nous innovons pour offrir des
                    solutions numériques de qualité, créant des expériences qui
                    inspirent confiance et boostent la croissance de nos
                    clients.
                  </p>
                  <div class="w3pt-call-box mt-4">
                    <div class="w3pt-call-info">
                      <h5>Restons en Contact</h5>

                      <h5>
                        <a href="tel:+237 674 882 527">
                          <i class="fas fa-phone-alt me-1"></i> (+237) 674 882
                          527
                        </a>
                      </h5>
                      <h5>
                        <a href="tel:+237 6 59 89 31 35">
                          <i class="fas fa-phone-alt me-1"></i> (+237) 6 59 89
                          31 35
                        </a>
                      </h5>
                      <h5>
                        <a href="tel:+237 654 753 799">
                          <i class="fas fa-phone-alt me-1"></i> (+237) 6 54 75
                          37 99
                        </a>
                      </h5>

                      <h5>
                        <i class="far fa-envelope me-1"></i>
                        <a href="mailto:contact@atomtechpro.com" class="mail">
                          contact@atomtechpro.com
                        </a>
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-2 col-md-6 footer-list-29 footerLinks footer-3 mt-lg-0 mt-5">
                <h6 class="footer-title-29">Liens</h6>
                <div class="footer-listw3-grids">
                  <ul class="footer-listw3 ulFooter">
                    <li>
                      <a href="/">Acceuil</a>
                    </li>
                    <li>
                      <a href="/about-us">À Propos de Nous</a>
                    </li>
                    <li>
                      <a href="/services">Nos Services</a>
                    </li>
                    <li>
                      <a href="/contact">Contactez-nous</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="col-lg-2 col-md-6 footer-list-29 footerLinks footer-3 mt-lg-0 mt-5">
                <h6 class="footer-title-29">Services</h6>
                <div class="footer-listw3-grids">
                  <ul class="footer-listw3 ulFooter">
                    <li>
                      <a href="/services">Développement Web</a>
                    </li>
                    <li>
                      <a href="#cardio">Marketing Digital</a>
                    </li>
                    <li>
                      <a href="/services">Design Graphique</a>
                    </li>
                    <li>
                      <a href="/services">Hébergement Web</a>
                    </li>
                    <li>
                      <a href="/services">Optimisation SEO</a>
                    </li>
                    <li>
                      <a href="/services">Etc...</a>
                    </li>
                  </ul>
                </div>
              </div>

              <div class="col-lg-4 col-md-6 footer-list-29 footerLinks footer-4 mt-lg-0 mt-5 ps-lg-5">
                <h6 class="footer-title-29">Restez connecté</h6>
                <p>
                  Recevez nos mises à jour en vous abonnant à notre newsletter
                  hebdomadaire
                </p>

                <form
                  ref={form}
                  onSubmit={sendEmail}
                  action=""
                  class="subscribe"
                  id="emailNewsletterFooter"
                  method="post"
                >
                  <input
                    type="email"
                    name="email"
                    placeholder="Votre adresse Email"
                    required=""
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <button>
                    <span class="fas fa-paper-plane"></span>
                  </button>
                </form>
                {emailError && <p style={{ color: "red" }}>{emailError}</p>}
                <div class="main-social-footer-29 mt-4">
                  <Link
                    to="https://web.facebook.com/atomtech.cm"
                    target="_blank"
                    class="facebook"
                  >
                    <span class="fab fa-facebook-f"></span>
                  </Link>
                  <Link
                    to="https://www.linkedin.com/company/atom-tech237/"
                    class="linkd"
                  >
                    <span class="fab fa-linkedin-in"></span>
                  </Link>
                  {/* <Link to="#twitter" class="twitter">
                    <span class="fab fa-twitter"></span>
                  </Link>
                  <Link to="#instagram" class="instagram">
                    <span class="fab fa-instagram"></span>
                  </Link> */}
                </div>
              </div>
            </div>
            <div class="bottom-copies">
              <p class="copy-footer-29 text-center" style={{ color: "white" }}>
                Copyright © 2024 ATOM TECH.
              </p>
            </div>
          </div>
        </div>

        {/* move top  */}
        <button onClick={scrollToTop} id="movetop" title="Go to top">
          <span class="fa fa-angle-up"></span>
        </button>
        {/* // move top */}
      </footer>
    </>
  );
}

export default Footer;
