import "../../Styles/ContactUspage.css";
import "../../Styles/style.css";
import Footer from "../Sections/Footer";
import Header from "../Sections/Header";
import { useEffect, useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import Spinner from "react-bootstrap/Spinner";

function ContactUspage() {
  useEffect(() => {
    // titre de la page
    document.title = "Contactez ATOM TECH - Parlons de Technologie";
  }, []);

  //preparation et envoi du mails d'inscription a la newsletter

  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [newsletterError, setNewsletterError] = useState("");
  const [newsletterSucces, setNewsletterSucces] = useState("");

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();
    if (!validateEmail(email)) {
      setEmailError("Veuillez entrer une adresse email valide.");
      return;
    } else {
      emailjs
        .sendForm("atom tech", "template_jsq25b5", form.current, {
          publicKey: "rEsMf1KtmhgfPjMuM",
          email: email,
        })
        .then(
          () => {
            setNewsletterSucces("Inscription réussi");
          },
          (error) => {
            setNewsletterError("Désolé, un problème est survenu");
          }
        );
    }
    setEmailError("");
  };

  //preparation et envoi du mails du formulaire de contact

  const [name, setName] = useState("");
  const [nameError, setNameError] = useState("");

  const [emailContact, setEmailContact] = useState("");
  const [emailContactError, setEmailContactError] = useState("");

  const [phone, setPhone] = useState("");
  const [phoneError, setPhoneError] = useState("");

  const [object, setObject] = useState("");
  const [objectError, setObjectError] = useState("");

  const [message, setMessage] = useState("");
  const [messageError, setMessageError] = useState("");

  const [messageEnvoie, setMessageEnvoie] = useState("");

  const validateEmailContact = (emailContact) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(emailContact);
  };

  const validatePhoneNumber = (phoneNumber) => {
    const phoneRegex = /^\+?[1-9]\d{1,14}$/; // International format (E.164)
    return phoneRegex.test(phoneNumber);
  };

  const handlePhoneChange = (i) => {
    let value = i.target.value;
    // Remove any non-numeric characters except the first "+"
    value = value.replace(/[^\d+]/g, "");

    // Ensure only one "+" at the start
    if (value.includes("+")) {
      const parts = value.split("+");
      value = "+" + parts.join("");
    }

    // Automatically add "+" at the start if the user types a number first
    if (value && !value.startsWith("+")) {
      value = "+" + value;
    }

    setPhone(value);
  };

  const formContact = useRef();

  const sendEmailContact = (i) => {
    i.preventDefault();
    if (
      name.length <= 4 ||
      !validateEmailContact(emailContact) ||
      !validatePhoneNumber(phone) ||
      object.length < 5 ||
      message.length < 15
    ) {
      // verification du nom
      if (name.length <= 4) {
        setNameError("Votre nom est trop court.");
      } else {
        setNameError("");
      }

      // verification de l'email
      if (!validateEmailContact(emailContact)) {
        setEmailContactError("Veuillez entrer une adresse email valide.");
      } else {
        setEmailContactError("");
      }

      // verification du numero
      if (!validatePhoneNumber(phone)) {
        setPhoneError("Veuillez sasir un numéro de téléphone valide.");
      } else {
        if (phone.length < 10) {
          setPhoneError("Veuillez sasir un numéro de téléphone valide.");
        } else {
          setPhoneError("");
        }
      }

      // verification de l'objet du message
      if (object.length < 5) {
        setObjectError("Veuillez sasir l'objet de votre message.");
      } else {
        setObjectError("");
      }

      // verification de du message
      if (message.length < 15) {
        setMessageError("Votre message est trop court.");
      } else {
        setMessageError("");
      }
    } else {
      setNameError("");
      setEmailContactError("");
      setPhoneError("");
      setObjectError("");
      setMessageError("");
      setMessageEnvoie("Veuillez patienter...");
      document.getElementById("textSendMessage").style.display = "block";

      emailjs
        .sendForm("atom tech", "template_wros7xp", formContact.current, {
          publicKey: "rEsMf1KtmhgfPjMuM",
          name: name,
          emailContact: emailContact,
          phone: phone,
          object: object,
          message: message,
        })
        .then(
          () => {
            document.getElementById("textSendMessage").style.display = "none";
            document.getElementById("name").value = "";
            document.getElementById("emailContact").value = "";
            setPhone("");
            document.getElementById("object").value = "";
            document.getElementById("message").value = "";
            alert("Nous avons bien reçu votre message.");
            document.location.href = "/";
          },
          (error) => {
            document.getElementById("textSendMessage").style.display = "none";
            alert("Désolé, un problème est survenu, veuillez réessayer !");
          }
        );
    }
  };

  return (
    <>
      {/* header */}
      <Header />
      {/* header */}

      {/* Header 2  */}
      <section class="w3l-about-breadcrumb">
        <div
          class="breadcrumb-bg breadcrumb-bg-about py-5"
          id="headerContactPage"
        >
          <div class="container py-lg-5 py-md-4">
            <div class="w3breadcrumb-gids text-center pt-5">
              <div class="w3breadcrumb-right" id="w3breadcrumb-right">
                <ul class="breadcrumbs-custom-path">
                  <li>
                    <a href="/">Acceuil</a>
                  </li>
                  <li class="active">
                    <span class="fas fa-angle-double-right mx-2"></span> contact{" "}
                  </li>
                </ul>
                <h1 className="titleAboutPage">Contactez-Nous</h1>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Header 2 */}

      {/* <!contact1*/}
      <section class="atom-contact-1 py-5" id="contact">
        <div class="contacts-9 py-lg-5 py-md-4">
          <div class="container">
            <div class="contactct-fm map-content-9">
              <div class="header-title text-center">
                <h3 class="title-atom mb-2" id="title2ContactPage">
                  Parlons de
                  <span class="span-bold"> votre projet</span>
                </h3>
                <p class="mb-sm-5 mb-4">
                  Démarrez avec nous pour accéder à une gamme complète de
                  services vous permettant de renforcer votre visibilité en
                  ligne, d'attirer du trafic qualifié et d'engager votre
                  audience. Notre engagement ? Résoudre vos défis en moins de 24
                  heures !
                </p>
              </div>
              <form
                ref={formContact}
                onSubmit={sendEmailContact}
                action=""
                class="pt-lg-4"
                method="post"
                id="contact"
              >
                <div class="twice-two">
                  <div class="">
                    <input
                      type="text"
                      class="form-control"
                      name="name"
                      id="name"
                      placeholder="Nom"
                      required=""
                      onChange={(i) => setName(i.target.value)}
                    />
                    {nameError && (
                      <p style={{ color: "red", textAlign: "left" }}>
                        {nameError}
                      </p>
                    )}
                  </div>
                  <div class="">
                    <input
                      type="email"
                      class="form-control"
                      name="emailContact"
                      id="emailContact"
                      placeholder="Email"
                      required=""
                      onChange={(i) => setEmailContact(i.target.value)}
                    />
                    {emailContactError && (
                      <p style={{ color: "red", textAlign: "left" }}>
                        {emailContactError}
                      </p>
                    )}
                  </div>
                </div>
                <div class="twice-two">
                  <div class="">
                    <input
                      type="phone"
                      class="form-control"
                      name="phone"
                      id="phone"
                      placeholder="Téléphone avec l'indicatif du pays"
                      required=""
                      // onChange={(i) => setPhone(i.target.value)}
                      onChange={handlePhoneChange}
                      value={phone}
                    />
                    {phoneError && (
                      <p style={{ color: "red", textAlign: "left" }}>
                        {phoneError}
                      </p>
                    )}
                  </div>
                  <div class="">
                    <input
                      type="text"
                      class="form-control"
                      name="object"
                      id="object"
                      placeholder="Objet"
                      required=""
                      onChange={(i) => setObject(i.target.value)}
                    />
                    {objectError && (
                      <p style={{ color: "red", textAlign: "left" }}>
                        {objectError}
                      </p>
                    )}
                  </div>
                </div>
                {/* <div class="row py-2"> */}
                <textarea
                  name="message"
                  class="form-control"
                  id="message"
                  placeholder="Message"
                  required=""
                  onChange={(i) => setMessage(i.target.value)}
                  style={{ marginBottom: "20px" }}
                ></textarea>
                {messageError && (
                  <p style={{ color: "red", textAlign: "left" }}>
                    {messageError}
                  </p>
                )}
                {/* </div> */}
                <div
                  class="text-lg-center"
                  style={{ display: "flex", alignItems: "left" }}
                >
                  <button
                    type="submit"
                    class="btn-style mt-lg-5 mt-1 btnReadMoreAboutHome"
                    id="btnSendMessageContact"
                    style={{}}
                  >
                    Envoyer
                  </button>
                  <p
                    style={{
                      color: "black",
                      marginLeft: "40px",
                      display: "none",
                    }}
                    id="textSendMessage"
                  >
                    {messageEnvoie}
                    <Spinner
                      animation="border"
                      variant="dark"
                      id="spinnerDisplay"
                    />
                  </p>
                </div>
              </form>
            </div>
            <div class="row contact-view my-5 py-lg-5">
              <div class="col-lg-6 cont-details">
                <div class="contactct-fm-text text-left">
                  <h6 class="" id="informationsContact">
                    <span>Trouvez-nous ici</span>
                  </h6>
                  <h3 class="title-atom mb-5">
                    Bureau de
                    <span class="span-bold"> Douala</span>
                  </h3>
                  <div class="cont-top">
                    <div class="cont-left text-center">
                      <span class="fas fa-phone-alt"></span>
                    </div>
                    <div class="cont-right">
                      <h5 className="informationsContact">
                        Numéros de Téléphone
                      </h5>
                      <p className="informationsContact">
                        <a href="tel:+(237) 6 74 88 25 27">
                          +(237) 6 74 88 25 27
                        </a>
                      </p>
                      <p className="informationsContact">
                        <a href="tel:+(237) 6 59 89 31 35">
                          +(237) 6 59 89 31 35
                        </a>
                      </p>
                      <p className="informationsContact">
                        <a href="tel:+(237) 6 54 75 37 99">
                          +(237) 6 54 75 37 99
                        </a>
                      </p>
                    </div>
                  </div>
                  <div class="cont-top margin-up">
                    <div class="cont-left text-center">
                      <span class="fas fa-envelope-open-text"></span>
                    </div>
                    <div class="cont-right">
                      <h5 className="informationsContact">Envoyer un mail</h5>
                      <p className="informationsContact">
                        <a href="mailto:contact@atomtechpro.com" class="mail">
                          contact@atomtechpro.com
                        </a>
                      </p>
                    </div>
                  </div>
                  <div class="cont-top margin-up">
                    <div class="cont-left text-center">
                      <span class="fas fa-map-marker-alt"></span>
                    </div>
                    <div class="cont-right">
                      <h5 className="informationsContact">
                        Addresse du bureau
                      </h5>
                      <p className="informationsContact pr-lg-5">
                        Douala - Cameroun.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-6 cont-details mt-lg-0 mt-5">
                <img
                  src="img/g7.jpg"
                  alt="image"
                  class="radius-image img-fluid"
                />
              </div>
            </div>
            <div class="map-content-9">
              <div class="map-iframe">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d254715.1500944226!2d9.576991603177493!3d4.035887107793933!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1061128be2e1fe6d%3A0x92daa1444781c48b!2sDouala!5e0!3m2!1sfr!2scm!4v1716744270923!5m2!1sfr!2scm"
                  width="100%"
                  height="400"
                  frameborder="0"
                  style={{ border: 0 }}
                  title="carteMaps"
                  allowfullscreen=""
                  loading="lazy"
                  referrerpolicy="no-referrer-when-downgrade"
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- /contact1 --> */}

      {/* newsletter */}
      <section class="atom-project-main pb-5" id="Newsletter">
        <div class="container pb-md-5 py-2">
          <div class="atom-project p-md-5 p-4 px-4">
            <div class="row py-lg-4 px-lg-2 align-items-center">
              <div class="col-lg-7 atom-project-right">
                <div class="bottom-info">
                  <div class="header-section pr-lg-5">
                    <h3 class="title-atom two">
                      Recevez nos mises à jour en vous abonnant à notre
                      <br />
                      <span class="span-bold">newsletter hebdomadaire !</span>
                    </h3>
                  </div>
                </div>
              </div>
              <div class="col-lg-5 atom-project-left mt-lg-0 mt-4">
                <form
                  ref={form}
                  onSubmit={sendEmail}
                  action=""
                  class="subscribe firstBlockNewsletter"
                  method="post"
                  required
                >
                  <input
                    type="email"
                    name="email"
                    placeholder="Votre adresse Email"
                    required=""
                    id="emailNewsletterService"
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <button>
                    <span class="fas fa-paper-plane"></span>
                  </button>
                </form>
                {emailError && (
                  <p style={{ color: "red", textAlign: "left" }}>
                    {emailError}
                  </p>
                )}
                {setNewsletterError && (
                  <p style={{ color: "red", textAlign: "left" }}>
                    {newsletterError}
                  </p>
                )}
                {setNewsletterError && (
                  <p style={{ color: "white", textAlign: "left" }}>
                    {newsletterSucces}
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* newsletter */}

      {/* footer */}
      <Footer />
      {/* footer */}
    </>
  );
}

export default ContactUspage;
