import "../../Styles/ServicesPages.css";
import "../../Styles/style.css";
import Header from "../Sections/Header";
import Footer from "../Sections/Footer";
import { useEffect, useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import AOS from "aos";

function ServicesPages() {
  useEffect(() => {
    // titre de la page
    document.title =
      "Nos Services - ATOM TECH | Développement Web, Marketing Digital, Design Graphique et Plus";
    // initialiser AOS
    AOS.init({
      // once: true,
      // disable: "phone",
      duration: 700,
      easing: "ease-out-cubic",
    });
  }, []);

  //preparation et envoi du mails d'inscription a la newsletter

  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [newsletterError, setNewsletterError] = useState("");
  const [newsletterSucces, setNewsletterSucces] = useState("");

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();
    if (!validateEmail(email)) {
      setEmailError("Veuillez entrer une adresse email valide.");
      return;
    } else {
      console.log(email.value);
      emailjs
        .sendForm("atom tech", "template_jsq25b5", form.current, {
          publicKey: "rEsMf1KtmhgfPjMuM",
          email: email,
        })
        .then(
          () => {
            setNewsletterSucces("Inscription réussi");
          },
          (error) => {
            setNewsletterError("Désolé, un problème est survenu");
          }
        );
    }
    setEmailError("");
  };

  return (
    <>
      <Header />

      {/* Header 2  */}
      <section class="atom-about-breadcrumb">
        <div
          class="breadcrumb-bg breadcrumb-bg-about py-2"
          id="headerServicesPage"
        >
          <div class="container py-lg-5 py-md-4">
            <div class="w3breadcrumb-gids text-center pt-5">
              <div class="w3breadcrumb-right" id="w3breadcrumb-right">
                <ul class="breadcrumbs-custom-path mt-2">
                  <li>
                    <a href="/">Acceuil</a>
                  </li>
                  <li class="active">
                    <span class="fas fa-angle-double-right mx-2"></span>{" "}
                    Services
                  </li>
                </ul>
                <h1 className="titleAboutPage">Nos Services</h1>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Header 2 */}

      {/* <about-section */}
      <section class="atom-index2" id="about" b>
        <div class="midd-w3 py-2">
          <div class="container py-md-5 py-3">
            {/* <!--/row-1--> */}
            <div class="row">
              <div
                data-aos="fade-right"
                class="col-lg-6 mt-lg-0 mt-md-5 mt-4 align-self ps-lg-5"
              >
                <div class="title-content text-left">
                  <h3 class="title-atom titleAboutHome">
                    Découvrez nos solutions sur mesure pour accompagner votre
                    <span class="span-bold"> Transformation Numérique.</span>
                  </h3>
                </div>
                <p class="mt-md-4 mt-3 textAboutus ">
                  Chez ATOM TECH, nous offrons une gamme complète de services
                  pour répondre aux besoins variés de nos clients. Que vous
                  soyez une entreprise cherchant à optimiser ses processus ou un
                  particulier souhaitant créer une présence en ligne, nous avons
                  la solution pour vous.
                </p>
                <div class="atom-two-buttons">
                  <a
                    href="/contact"
                    class="btn-style mt-lg-5 mt-4 ms-2 btnContactUsAboutHome"
                  >
                    Contactez-nous
                    <span class="fas fa-angle-double-right ms-2"></span>
                  </a>
                </div>
              </div>
              <div data-aos="zoom-in" class="col-lg-6">
                <div class=" imageAboutHome">
                  <img
                    src="img/g10.jpg"
                    alt="contact"
                    class="radius-image img-fluid"
                  />
                </div>
              </div>
            </div>
            {/* <!--//row-1--> */}
          </div>
        </div>
      </section>
      {/* <about-section */}

      {/* Developpement web */}
      <section class="atom-grids-3 py-2">
        <div class="container py-md-5 py-3">
          <div class="row bottom-ab-grids align-items-center">
            <div class="header-sec text-center">
              <h3
                data-aos="zoom-in"
                class="title-atom mb-3"
                id="h3TitleCategorieService"
              >
                <span class="span-bold" id="categorieServices">
                  I. Développement Web
                </span>
              </h3>
            </div>
          </div>
          <div class="row bottom_grids pt-md-3 text-left">
            <div class="col-lg- col-md-6 mt-2">
              <div class="grid-block">
                <a
                  href="/contact"
                  class="d-block p-lg-4 p-3"
                  id="linkSousServices"
                >
                  <span class="fas fa-file-alt" aria-hidden="true"></span>
                  <h4 class="my-3">
                    Conception de sites <br />
                    vitrines
                  </h4>
                  <p class="">
                    Votre vitrine digitale, élégante et percutante.{" "}
                  </p>
                </a>
              </div>
            </div>
            <div class="col-lg- col-md-6 mt-2">
              <div class="grid-block">
                <a
                  href="/contact"
                  class="d-block p-lg-4 p-3"
                  id="linkSousServices"
                >
                  <span class="fas fa-holly-berry" aria-hidden="true"></span>
                  <h4 class="my-3">
                    Développement de sites <br />
                    e-commerce
                  </h4>
                  <p class="">
                    Vendez en ligne facilement et en toute sécurité.{" "}
                  </p>
                </a>
              </div>
            </div>
            <div class="col-lg- col-md-6 mt-2">
              <div class="grid-block">
                <a
                  href="/contact"
                  class="d-block p-lg-4 p-3"
                  id="linkSousServices"
                >
                  <span
                    class="fas fa-american-sign-language-interpreting"
                    aria-hidden="true"
                  ></span>
                  <h4 class="my-3">
                    Développement d'applications <br />
                    sur mesure
                  </h4>

                  <p class="">
                    Applications personnalisés pour vos besoins spécifiques.{" "}
                  </p>
                </a>
              </div>
            </div>
            <div class="col-lg- col-md-6 mt-2">
              <div class="grid-block">
                <a
                  href="/contact"
                  class="d-block p-lg-4 p-3"
                  id="linkSousServices"
                >
                  <span class="fa fa-cubes" aria-hidden="true"></span>
                  <h4 class="my-3">
                    Intégration et maintenance <br />
                    de CMS
                  </h4>
                  <p class="">
                    Gérez vous meme votre site sans effort avec notre aide.{" "}
                  </p>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Developpeemnt web */}

      {/* Marketing digital */}
      <section class="atom-grids-3 py-2">
        <div class="container py-md-5 py-3">
          <div class="row bottom-ab-grids align-items-center">
            <div class="header-sec text-center">
              <h3
                data-aos="zoom-in"
                class="title-atom mb-3"
                id="h3TitleCategorieService"
              >
                <span class="span-bold" id="categorieServices">
                  II. Marketing Digital
                </span>
              </h3>
            </div>
          </div>
          <div class="row bottom_grids pt-md-3 text-left">
            <div class="col-lg- col-md-6 mt-2">
              <div class="grid-block">
                <a
                  href="/contact"
                  class="d-block p-lg-4 p-3"
                  id="linkSousServices"
                >
                  <span class="fas fa-file-alt" aria-hidden="true"></span>
                  <h4 class="my-3">
                    Stratégies de Marketing en ligne <br />
                  </h4>
                  <p class="">
                    Des stratégies digitales efficaces pour une <br />
                    visibilité maximale.{" "}
                  </p>
                </a>
              </div>
            </div>
            <div class="col-lg- col-md-6 mt-2">
              <div class="grid-block">
                <a
                  href="/contact"
                  class="d-block p-lg-4 p-3"
                  id="linkSousServices"
                >
                  <span class="fas fa-holly-berry" aria-hidden="true"></span>
                  <h4 class="my-3">
                    Gestion des réseaux sociaux <br />
                  </h4>
                  <p class="">
                    Booster votre présence sociale <br />
                    efficacement.{" "}
                  </p>
                </a>
              </div>
            </div>
            <div class="col-lg- col-md-6 mt-2">
              <div class="grid-block">
                <a
                  href="/contact"
                  class="d-block p-lg-4 p-3"
                  id="linkSousServices"
                >
                  <span
                    class="fas fa-american-sign-language-interpreting"
                    aria-hidden="true"
                  ></span>
                  <h4 class="my-3">
                    Campagnes publicitaires
                    <br />
                  </h4>

                  <p class="">
                    Publicité ciblée pour des resultats <br />
                    immédiats.{" "}
                  </p>
                </a>
              </div>
            </div>
            <div class="col-lg- col-md-6 mt-2">
              <div class="grid-block">
                <a
                  href="/contact"
                  class="d-block p-lg-4 p-3"
                  id="linkSousServices"
                >
                  <span class="fa fa-cubes" aria-hidden="true"></span>
                  <h4 class="my-3">
                    Création de contenu <br />
                  </h4>
                  <p class="">
                    Contenu créatif qui capte <br />
                    l'attention.{" "}
                  </p>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Marketing digital */}

      {/* Design Graphique */}
      <section class="atom-grids-3 py-2">
        <div class="container py-md-5 py-3">
          <div class="row bottom-ab-grids align-items-center">
            <div class="header-sec text-center">
              <h3
                data-aos="zoom-in"
                class="title-atom mb-3"
                id="h3TitleCategorieService"
              >
                <span class="span-bold" id="categorieServices">
                  III. Branding et Design Graphique
                </span>
              </h3>
            </div>
          </div>
          <div class="row bottom_grids pt-md-3 text-left">
            <div class="col-lg- col-md-6 mt-2">
              <div class="grid-block">
                <a
                  href="/contact"
                  class="d-block p-lg-4 p-3"
                  id="linkSousServices"
                >
                  <span class="fas fa-file-alt" aria-hidden="true"></span>
                  <h4 class="my-3">
                    Création de logo <br />
                  </h4>
                  <p class="">Logos unique qui marquent les esprits. </p>
                </a>
              </div>
            </div>
            <div class="col-lg- col-md-6 mt-2">
              <div class="grid-block">
                <a
                  href="/contact"
                  class="d-block p-lg-4 p-3"
                  id="linkSousServices"
                >
                  <span class="fas fa-holly-berry" aria-hidden="true"></span>
                  <h4 class="my-3">
                    Charte graphique <br />
                  </h4>
                  <p class="">
                    Identité visuelle cohérente et professionnelle.{" "}
                  </p>
                </a>
              </div>
            </div>
            <div class="col-lg- col-md-6 mt-2">
              <div class="grid-block">
                <a
                  href="/contact"
                  class="d-block p-lg-4 p-3"
                  id="linkSousServices"
                >
                  <span
                    class="fas fa-american-sign-language-interpreting"
                    aria-hidden="true"
                  ></span>
                  <h4 class="my-3">
                    Création de visuels de communication
                    <br />
                  </h4>

                  <p class="">
                    Publicité ciblée pour des resultats <br />
                    immédiats.{" "}
                  </p>
                </a>
              </div>
            </div>
            <div class="col-lg- col-md-6 mt-2">
              <div class="grid-block">
                <a
                  href="/contact"
                  class="d-block p-lg-4 p-3"
                  id="linkSousServices"
                >
                  <span class="fa fa-cubes" aria-hidden="true"></span>
                  <h4 class="my-3">
                    Design UI/UX <br />
                  </h4>
                  <p class="">
                    Expériences utilisateurs intuitives <br />
                    et agréables.{" "}
                  </p>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Design Graphique */}

      {/* Hebergement web */}
      <section class="atom-grids-3 py-2">
        <div class="container py-md-5 py-3">
          <div class="row bottom-ab-grids align-items-center">
            <div class="header-sec text-center">
              <h3
                data-aos="zoom-in"
                class="title-atom mb-3"
                id="h3TitleCategorieService"
              >
                <span class="span-bold" id="categorieServices">
                  IV. Hébergement Web
                </span>
              </h3>
            </div>
          </div>
          <div class="row bottom_grids pt-md-3 text-left">
            <div class="col-lg- col-md-4 mt-2">
              <div class="grid-block">
                <a
                  href="/contact"
                  class="d-block p-lg-4 p-3"
                  id="linkSousServices"
                >
                  <span class="fas fa-file-alt" aria-hidden="true"></span>
                  <h4 class="my-3">
                    Hébergement cloud <br />
                  </h4>
                  <p class="">
                    Flexibité et scalabilité pour votre croissance.{" "}
                  </p>
                </a>
              </div>
            </div>
            <div class="col-lg- col-md-4 mt-2">
              <div class="grid-block">
                <a
                  href="/contact"
                  class="d-block p-lg-4 p-3"
                  id="linkSousServices"
                >
                  <span class="fas fa-holly-berry" aria-hidden="true"></span>
                  <h4 class="my-3">
                    Nom de domaine <br />
                  </h4>
                  <p class="">Votre domaine, notre expertise. </p>
                </a>
              </div>
            </div>
            <div class="col-lg- col-md-4 mt-2">
              <div class="grid-block">
                <a
                  href="/contact"
                  class="d-block p-lg-4 p-3"
                  id="linkSousServices"
                >
                  <span
                    class="fas fa-american-sign-language-interpreting"
                    aria-hidden="true"
                  ></span>
                  <h4 class="my-3">
                    Serveurs dédiés
                    <br />
                  </h4>

                  <p class="">
                    Puissance exclusive pour vos projets exigeants.{" "}
                  </p>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Hebergement web */}

      {/* SEO */}
      <section class="atom-grids-3 py-2">
        <div class="container py-md-5 py-3">
          <div class="row bottom-ab-grids align-items-center">
            <div class="header-sec text-center">
              <h3
                data-aos="zoom-in"
                class="title-atom mb-3"
                id="h3TitleCategorieService"
              >
                <span class="span-bold" id="categorieServices">
                  V. SEO (Search Engine Optimization)
                </span>
              </h3>
            </div>
          </div>
          <div class="row bottom_grids pt-md-3 text-left">
            <div class="col-lg- col-md-4 mt-2">
              <div class="grid-block">
                <a
                  href="/contact"
                  class="d-block p-lg-4 p-3"
                  id="linkSousServices"
                >
                  <span class="fas fa-file-alt" aria-hidden="true"></span>
                  <h4 class="my-3">
                    Recherche de mots clés <br />
                  </h4>
                  <p class="">
                    Identifez les meilleurs mots-clés pour votre niche.{" "}
                  </p>
                </a>
              </div>
            </div>
            <div class="col-lg- col-md-4 mt-2">
              <div class="grid-block">
                <a
                  href="/contact"
                  class="d-block p-lg-4 p-3"
                  id="linkSousServices"
                >
                  <span class="fas fa-holly-berry" aria-hidden="true"></span>
                  <h4 class="my-3">
                    Création de contenu optimisé <br />
                  </h4>
                  <p class="">Contenu SEO qui se classe en haut. </p>
                </a>
              </div>
            </div>
            <div class="col-lg- col-md-4 mt-2">
              <div class="grid-block">
                <a
                  href="/contact"
                  class="d-block p-lg-4 p-3"
                  id="linkSousServices"
                >
                  <span
                    class="fas fa-american-sign-language-interpreting"
                    aria-hidden="true"
                  ></span>
                  <h4 class="my-3">
                    Audit SEO
                    <br />
                  </h4>

                  <p class="">
                    Analyse SEO approfondie pour des résultats tangibles.{" "}
                  </p>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* SEO */}

      {/* Conseil en Transformation Digitale */}
      <section class="atom-grids-3 py-2">
        <div class="container py-md-5 py-3">
          <div class="row bottom-ab-grids align-items-center">
            <div class="header-sec text-center">
              <h3
                data-aos="zoom-in"
                class="title-atom mb-3"
                id="h3TitleCategorieService"
              >
                <span class="span-bold" id="categorieServices">
                  VI. Conseil en Transformation Digitale
                </span>
              </h3>
            </div>
          </div>
          <div class="row bottom_grids pt-md-3 text-left">
            <div class="col-lg- col-md-4 mt-2">
              <div class="grid-block">
                <a
                  href="/contact"
                  class="d-block p-lg-4 p-3"
                  id="linkSousServices"
                >
                  <span class="fas fa-file-alt" aria-hidden="true"></span>
                  <h4 class="my-3">Analyse des besoins</h4>
                  <p class="">Diagnostique précis de vos besoins digitaux. </p>
                </a>
              </div>
            </div>
            <div class="col-lg- col-md-4 mt-2">
              <div class="grid-block">
                <a
                  href="/contact"
                  class="d-block p-lg-4 p-3"
                  id="linkSousServices"
                >
                  <span class="fas fa-holly-berry" aria-hidden="true"></span>
                  <h4 class="my-3">
                    Planification de la transformation numérique
                  </h4>
                  <p class="">Planifiez votre avenir digital. </p>
                </a>
              </div>
            </div>
            <div class="col-lg- col-md-4 mt-2">
              <div class="grid-block">
                <a
                  href="/contact"
                  class="d-block p-lg-4 p-3"
                  id="linkSousServices"
                >
                  <span
                    class="fas fa-american-sign-language-interpreting"
                    aria-hidden="true"
                  ></span>
                  <h4 class="my-3">
                    Mise en oeuvre et suivi
                    <br />
                  </h4>

                  <p class="">Transformation digitale sans accroc.</p>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Hebergement web */}

      {/* newsletter */}
      <section class="atom-project-main pb-5" id="Newsletter">
        <div class="container pb-md-5 py-2">
          <div class="atom-project p-md-5 p-4 px-4">
            <div class="row py-lg-4 px-lg-2 align-items-center">
              <div class="col-lg-7 atom-project-right">
                <div class="bottom-info">
                  <div class="header-section pr-lg-5">
                    <h3 class="title-atom two">
                      Recevez nos mises à jour en vous abonnant à notre
                      <br />
                      <span class="span-bold">newsletter hebdomadaire !</span>
                    </h3>
                  </div>
                </div>
              </div>
              <div class="col-lg-5 atom-project-left mt-lg-0 mt-4">
                <form
                  ref={form}
                  onSubmit={sendEmail}
                  action=""
                  class="subscribe firstBlockNewsletter"
                  method="post"
                  required
                >
                  <input
                    type="email"
                    name="email"
                    placeholder="Votre adresse Email"
                    required=""
                    id="emailNewsletterService"
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <button>
                    <span class="fas fa-paper-plane"></span>
                  </button>
                </form>
                {emailError && (
                  <p style={{ color: "red", textAlign: "left" }}>
                    {emailError}
                  </p>
                )}
                {setNewsletterError && (
                  <p style={{ color: "red", textAlign: "left" }}>
                    {newsletterError}
                  </p>
                )}
                {setNewsletterError && (
                  <p style={{ color: "white", textAlign: "left" }}>
                    {newsletterSucces}
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* newsletter */}

      <Footer />
    </>
  );
}

export default ServicesPages;
